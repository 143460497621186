import { render, staticRenderFns } from "./basic.vue?vue&type=template&id=5d1d9628&scoped=true&"
import script from "./basic.vue?vue&type=script&lang=js&"
export * from "./basic.vue?vue&type=script&lang=js&"
import style0 from "./basic.vue?vue&type=style&index=0&id=5d1d9628&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d1d9628",
  null
  
)

export default component.exports