<template>
  <div class="spec_warp">
    <div class="specification">
      <div class="title">服务规格</div>
      <ch-form :render-option="specifyOption" :model="specifyModalForm"
               :splice="[10]" ref="specifyForm" :rules="specifyRules"
               :props="{ labelWidth: '150px' , paddingRight: '80px'}" >
        <template #ServiceDet>
          <div>
            <div style="display: flex;">
              <div style="display: flex;width:400px;flex-wrap: wrap">
                <el-input v-for="(item,index) in specifyModalForm.serviceDet" v-model="item.text" :key="index" maxlength="20"
                          type="text" show-word-limit size="small" placeholder='自定义输入服务明细，例如"全屋清扫"' />
              </div>
              <div>
                <ch-button style="height: 30px" @click="delService" type="cancel">删除</ch-button>
                <ch-button style="height: 30px" @click="addService" type="ok">添加</ch-button>
              </div>
            </div>
            <div style="font-size: 14px;color: #0000003F">可自定义输入服务明细，如"全屋清扫"，添加了服务明细之后服务人员只能完成明细之后才可正常签离</div>
          </div>
        </template>
      </ch-form>
    </div>

    <div class="tableData">
      <div class="title">服务价格<span style="color: red">*</span></div>
      <el-table
          :data="tableData"
          style="width:100%;margin: 20px 0"
          size="mini" :border="true" :header-cell-style="{background:'#CCCCCC'}"
      >
        <el-table-column prop="specName" label="规格名称" fixed="left" show-overflow-tooltip min-width="200"></el-table-column>
        <el-table-column prop="customTagImg" label="自定义图标" fixed="left" show-overflow-tooltip min-width="100">
          <div class="item" slot-scope="scope">
            <ch-upload-img action="/hm/general/upload" :img-length="1" :uploadSize="40" :uploadResponse="handleResponse.bind(this, scope.$index)"
                           :removeResponse="handleRemoveResponse.bind(this, scope.$index)" :fileList.sync="scope.row.customTagImgField" >
            </ch-upload-img>
          </div>
        </el-table-column>
        <el-table-column  label="自定义标签" fixed="left" min-width="100">
          <div class="item" slot-scope="scope">
            <el-input  v-model="scope.row.customTag" size="small" placeholder='请输入' />
          </div>
        </el-table-column>
        <el-table-column prop="stipulate" label="房屋面积/㎡" fixed="left" min-width="100">
          <div  class="item" slot-scope="scope">
            <el-input size="mini" class="item__input" v-model="scope.row.stipulate" placeholder="面积"></el-input>
          </div>
        </el-table-column>
        <el-table-column prop="duration" label="服务时长/小时" fixed="left" min-width="120"></el-table-column>
        <div v-for="(item,index) in tableSpecHeader" :key="index">
          <el-table-column prop="once"  :label="`${item}次(元)原价/售价`" min-width="200">
            <div  class="item" style="display: flex;align-items: center;flex-wrap: wrap" slot-scope="scope">
              <el-input size="mini" class="item__input" style="margin: 3px" :value="scope.row.specItems[index]?.originalPrice"
                        @input="(value)=> scope.row.specItems[index].originalPrice = value.replace(/[^0-9.]/g,'')" @change="() => {
                          scope.row.specItems[index].originalPrice = scope.row.specItems[index].originalPrice? parseFloat(scope.row.specItems[index].originalPrice, 10).toFixed(2):0.00;
                        }"
                        placeholder="原价"></el-input>
              <el-input size="mini" class="item__input" style="margin: 3px" :value="scope.row.specItems[index]?.price" @input="(value)=> scope.row.specItems[index].price = value.replace(/[^0-9.]/g,'')" @change="() => {
                          scope.row.specItems[index].price = scope.row.specItems[index].price? parseFloat(scope.row.specItems[index].price, 10).toFixed(2):0.00;
                          if(scope.$index===0){
                            minPrice = scope.row.specItems[index].price;
                          }
                        }" placeholder="售价"></el-input>
              <el-checkbox-group  v-model="scope.row.specItems[index].activityIds" style="display: flex;flex-wrap: wrap">
                <el-checkbox  class="right-no-checkbox" v-for="(item,index) in curActivityObj" :key="index"   size="mini" :label="item.activityId" border>
                  <el-tooltip style="padding: 0" effect="dark" :content="item.name" placement="top-start">
                    <el-button>{{item.name.slice(0,3)}}</el-button>
                  </el-tooltip>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-table-column>
        </div>
      </el-table>
      <div style="margin-bottom: 10px;">
        显示价格：
        <el-select v-model="specifyModalForm.minPrice" size="mini" placeholder="请选择" style="margin-right: 5px">
           <el-option
              v-for="(item,index) in priceOption"
              :key="index"
              :label="item.label"
              :value="item.value">
           </el-option>
        </el-select>元
      </div>
      <div style="margin-bottom: 22px;display: flex;align-items: center;">
        夜间服务：<el-checkbox @change="nightSonChange" v-model="nightSon.nightService" :true-label="1" :false-label="0" />
        <el-radio-group style="margin-left: 20px;" v-model="nightSon.raisePriceType" @change="raisePriceInput">
          <el-radio :label="1">单次固定加价<el-input v-model="nightSon.raisePriceFixation" @focus="inputFocus(1)" @input="inputValueReplace($event,'raisePriceFixation')" @blur="handleBlur('raisePriceFixation')" size="mini" style="margin:0 5px;width: 70px"></el-input>元</el-radio>
          <el-radio :label="2">单次按比例加价<el-input v-model="nightSon.raisePricePercentage" @focus="inputFocus(2)" @input="inputValueReplace($event,'raisePricePercentage')" @blur="handleBlur('raisePricePercentage')" size="mini" style="margin:0 5px;width: 70px"></el-input>%</el-radio>
        </el-radio-group>
      </div>
      <div class="footer" style="color: #0000003f;font-size: 14px">
        <ul>
          <li>1.单价、折扣为必填项</li>
          <li>2.单价为最低服务时长的价格</li>
          <li>3.请谨慎填写服务时长的价格。服务N时长价格=最低服务时长的单价*折扣(折扣价设置为准)，不设置折扣价可自定于填写价格</li>
        </ul>
      </div>
    </div>

  </div>
</template>
<script>
import {Message} from "element-ui";
import _ from "lodash";

export default {
  props:{
    modalData:{
      type:Object,
      default:function (){
        return {}
      }
    },
    table:{
      type:Array,
    },
    night: {
      type: Object
    },
    curActivityObj: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  created() {
    this.$nextTick(()=>{
      this.$refs.specifyForm?.clearValidate();
      if(this.modalData.specName||this.modalData.serviceTime) {
        this.specifyModalForm = Object.assign({}, this.modalData)
        this.specifyModalForm.serviceTime = this.modalData.serviceTime.sort()
      }
      this.tableData = this.table.map((item) => {
        if(item.specItems.length<3){
          item.specItems = [
            ...this.specList.filter(spec =>
                !item.specItems.some(itemSpec => itemSpec.number === spec.number)
            ),
            ...item.specItems
          ].sort((a, b) => a.number - b.number);
        }
        item.specItems = item.specItems.map((spec) => {
          return {
            ...spec,
            activityIds: spec.activityIds && spec.activityIds.length > 0 ? spec.activityIds.split(',').map(Number) || [] : []
          }
        })
        item.customTag = item.customTag || item.duration + '小时'
        item.customTagImgField = item.customTagImg? item.customTagImg.split(',').map(c => ({url:c})) : []
        return {
          ...item,
        }
      })
      if(this.night.nightService !==undefined) {
        this.nightSon = Object.assign({}, this.night)
      }
      if(this.nightSon.nightService===0) {
        this.nightSon.raisePriceType = 0
        this.nightSon.raisePrice = ''
      }
      if(this.nightSon.raisePriceType===1){
        this.nightSon.raisePriceFixation = this.nightSon.raisePrice
        this.nightSon.raisePricePercentage = ''
      }else if(this.nightSon.raisePriceType===2){
        this.nightSon.raisePriceFixation = ''
        this.nightSon.raisePricePercentage = this.nightSon.raisePrice
      }
    })
  },
  data(){
    return {
      specifyModalForm:{
        specName:'',
        serviceTime: [],
        overtimePay:'',
        specDetail:'',
        serviceDet:[
          {text:''},
        ],
        minPrice:'',
        customTagImgField: [],
        customTag: '',
        customTagImg: '',
      },
      tableData:[],
      specifyRules:{
        specName: [{ required: true, message: "请输入规格名称", trigger: ["blur", "change"] }],
        serviceTime: [{ required: true, message: "请选择服务时长", trigger: ["blur",] }],
        overtimePay: [{ required: true, message: "请输入单次超时价格", trigger: ["blur", "change"] }],
      },
      tableSpecHeader: [1,2,3],
      specList: [
        {activityIds: '', number: 1, price: '', originalPrice: ''},
        {activityIds: '', number: 2, price: '', originalPrice: ''},
        {activityIds: '', number: 3, price: '', originalPrice: ''}
      ],
      nightSon:{
        nightService: 0, // 夜间服务
        raisePriceType: 0 ,// 加价类型(0:不加,1:固定,2:百分比)
        raisePrice: null, // 加价
        raisePriceFixation: null,
        raisePricePercentage: null,
      },
    }
  },
  watch: {
    'specifyModalForm.serviceTime':_.debounce(function (val) {
      this.specifyModalForm.serviceTime = val?.sort()
      if (val && this.specifyModalForm.serviceTime?.length > 0 ) {
        this.specifyModalForm.serviceTime.forEach((time) => {
          const specItems = JSON.parse(JSON.stringify(this.specList))
          // 响应式data的数据
          let data = {
            specName: this.specifyModalForm.specName,
            stipulate: '<50',
            duration: time,
            specItems: specItems,
            customTagImgField: [],
            customTag: time + '小时',
            customTagImg: '',
          }
          this.tableData.push(data)
          // 根据时间去重
          this.tableData = _.uniqBy(this.tableData, 'duration')?.sort((a, b) => {
            return a.duration - b.duration
          }).filter(item => this.specifyModalForm.serviceTime.includes(item.duration))
        })
      }
    }),
    'specifyModalForm.specName':_.debounce(function (val){
      if(val){
        this.tableData = this.tableData.map((item)=>{
          return {
            ...item,
            specName:val
          }
        })
      }
    },300),
  },
  computed:{
    specifyOption:function (){
      return [
        {type: 'input', label: '规格名称：', prop: 'specName', placeholder: '单次套餐填服务名称，多次套餐命名为：服务名称+服务次数，例：日常保洁3次',
          props:{showWordLimit:true,maxlength:20} },
        {type: 'select', label: '服务时长：', prop: 'serviceTime', placeholder: '例:2，3，4，5,最多可选4个时间',
          name: "name", value: "name",props: { multiple: true,multipleLimit:4},
          option: [
            {name:1}, {name:2}, {name:3}, {name:4}, {name:5},
            {name:6}, {name:7}, {name:8}, {name:9}, {name:10},
          ],
        },
        {type: 'number', label: '单次超时价格：', prop: 'overtimePay', placeholder: '请输入以小时为单位，单次超时服务工作价格',sign:'元'},
        {type: 'input', label: '规格描述：', prop: 'specDetail', placeholder: '请输入' },
        {type: 'slot',  label: '服务明细：', slotName: 'ServiceDet',prop: 'serveDetail'},
      ]
    },
    priceOption:function(){
      return this.tableData.map((item)=>{
        return item.specItems.map((specItem)=> specItem.price)
      }).flat().filter(item => item).sort((a,b) => (a - b)).reduce((acc, item) => {
        if (!acc.some((obj) => obj.value === item)) {
          acc.push({ value: item, label: item });
        }
        return acc;
      }, []);
    }
  },
  methods:{
    addService(){
      if(this.specifyModalForm.serviceDet.length>=10){
        Message({type: "error", message: "最多只能添加10个服务明细!",})
      }
      this.specifyModalForm.serviceDet.push({text:""})
    },
    delService(){
      if(this.specifyModalForm.serviceDet.length<=1){
        Message({type: "error", message: "不可以继续删除了!",})
        return
      }
      this.specifyModalForm.serviceDet.pop()
    },
    getData(){
      return this.specifyModalForm
    },
    getTableDate(){
      this.tableData.forEach((item)=>{
        item.specItems = item.specItems.map(spec => {
          return {
            ...spec,
            activityIds: spec.activityIds? spec.activityIds?.join(',') || '':''
          }
        })
      })
      return this.tableData
    },
    getNightServe() {
      this.nightSon.raisePrice = this.nightSon.raisePriceFixation || this.nightSon.raisePricePercentage
      return this.nightSon
    },
    nightSonChange(event){
      if(event===0){
        this.nightSon.raisePriceType = 0
        this.nightSon.raisePrice = ''
        this.nightSon.raisePriceFixation = ''
        this.nightSon.raisePricePercentage = ''
      }
    },
    raisePriceInput() {
      this.$forceUpdate()
      this.nightSon.raisePrice = ''
      this.nightSon.raisePriceFixation = ''
      this.nightSon.raisePricePercentage = ''
    },
    handleBlur(prop) {
      this.nightSon[prop] = this.nightSon[prop] ? parseFloat(this.nightSon[prop], 10).toFixed(2) : '';
    },
    inputValueReplace(value,prop) {
      this.nightSon[prop] = value.replace(/[^0-9.]/g,'')
    },
    inputFocus(type) {
      this.nightSon.nightService = 1
      if (type === 1){
        this.nightSon.raisePriceType = 1
      }else {
        this.nightSon.raisePriceType = 2
      }
      this.nightSon.raisePrice = ''
      this.nightSon.raisePriceFixation = ''
      this.nightSon.raisePricePercentage = ''
    },
    handleResponse(index,res){
      this.tableData[index].customTagImg = res.data.url
      this.tableData[index].customTagImgField = [{url: res.data.url}]
    },
    handleRemoveResponse(index) {
      this.tableData[index].customTagImg = ''
      this.tableData[index].customTagImgField = []
    },
  },
  destroyed() {
    this.nightSon = {}
  }
}
</script>
<style scoped lang="scss">
.spec_warp{
  .specification,
  .tableData{
    padding: 20px 40px;
    .tagsList{
      .select_warp{
        display: flex;
        .button{
          height: 30px;
          line-height: 0;
        }
      }
      .tip{
        color: #999999;
        font-size: 12px;
      }
    }
  }
  .tableData{
    padding: 20px 40px;
    .item__input{
      width: 60px;
      /* 调整elementUI中样式 如果不需要调整请忽略 */
      ::v-deep .el-input__inner{
        padding: 0 5px !important;
        text-align: center;
      }
      .el-input__suffix{
        i{
          font-size: 12px !important;
          line-height: 26px !important;
        }
      }
    }
    .item__txt{
      box-sizing: border-box;
      line-height: 30px;
      padding: 0 9px;
    }
  }
  .specification {
    .title {
      margin-bottom: 10px;
    }
  }
}
.title{
  font-weight: bold;
}
::v-deep {
  .right-no-checkbox {
    margin-left: 3px !important;
    margin-right: 0 !important;
    margin-bottom: 3px;
  }
  .right-no-checkbox:last-child {
    margin-bottom: 0 !important;
  }
}
</style>